<template>
  <el-row class="table">
    <el-alert
      title="注意问题"
      type="warning"
      description="出勤范围在0~65535，连续且不重叠，班容类型有三种（常规、竞赛、信竞），每种都需满足前面的条件"
      show-icon>
    </el-alert>
    <el-table
      style="margin-top: 10px;"
      :data="dataList"
      border
      fit
      highlight-current-row
      class="tb-list"
    >
      <el-table-column label="最小出勤人数（含）" align="center">
        <template slot-scope="scope">
          <template v-if="scope.row.isEdit">
            <el-input  v-model="scope.row.min" placeholder="请填写最小出勤人数" oninput="value=value.replace(/[^0-9]/gi, '')"></el-input>
          </template>
          <span v-else>{{scope.row.min}}（人）</span>
        </template>
      </el-table-column>
      <el-table-column label="最大出勤人数（含）" align="center">
        <template slot-scope="scope">
          <template v-if="scope.row.isEdit">
            <el-input  v-model="scope.row.max" placeholder="请填写最大出勤人数" oninput="value=value.replace(/[^0-9]/gi, '')"></el-input>
          </template>
          <span v-else>{{scope.row.max}}（人）</span>
        </template>
      </el-table-column>
      <el-table-column label="班容系数（%）" align="center">
        <template slot-scope="scope">
          <template v-if="scope.row.isEdit">
            <el-input  v-model="scope.row.coefficient" placeholder="请填写班容系数" oninput="value=value.replace(/[^0-9]/gi, '')"></el-input>
          </template>
          <span v-else>{{scope.row.coefficient}}</span>
        </template>
      </el-table-column>
      <el-table-column label="班容类型" align="center">
        <template slot-scope="scope">
          <template v-if="scope.row.isEdit">
            <el-select
              v-model="scope.row.type"
              placeholder="请选择班容类型"
              clearable
              filterable
            >
              <el-option
                v-for="item in lctypeArray"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              ></el-option>
            </el-select>
          </template>
          <span v-else>{{scope.row.type}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250" align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" v-if="!scope.row.isEdit" @click="edit(scope.row, scope.$index)">编 辑</el-button>
          <template v-else>
            <el-button type="info" size="mini" @click="close(scope.row, scope.$index)">取 消</el-button>
          </template>
          <el-button type="danger" size="mini" v-if="!scope.row.isEdit" @click="del(scope.row, scope.$index)">删 除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-row style="background: #fff;text-align: center;" class="add-container">
      <el-button type="info" size="small" @click="add()">新 增</el-button>
      <el-button type="success" size="small" @click="save()" v-if="preserve()">保 存</el-button>
    </el-row>

  </el-row>
</template>

<script>
import { getListLessonCoefficient, updateLessonCoefficient } from '@/api/salary'

export default {
  created() {
    this.getListLessonCoefficient()
  },
  data () {
    return {
      dataList: [],
      lctypeArray:[
        {
          value:'常规'
        },{
          value:'竞赛'
        },{
          value:'信竞'
        },
      ],
      formData: {},
      rules: {
        min: [{ required: true, message: '请填写最小出勤人数', trigger: 'blur' }],
        max: [{ required: true, message: '请填写最大出勤人数', trigger: 'blur' }],
        coefficient: [{ required: true, message: '请填写班容系数', trigger: 'blur' }],
      }
    }
  },
  methods: {
    async getListLessonCoefficient () {
      const res = await getListLessonCoefficient({
      })
      const dataList = res.body
      dataList.forEach(ele => {
        ele.isEdit = false
      });
      this.dataList = dataList
    },

    search() {
      this.pagination.currentPage = 1
      this.getListLessonCoefficient()
    },

    preserve() {
      return this.dataList.some(item => item.isEdit === true)
    },

    setting() {
      this.$refs.formData.validate(valid => {
        if (valid) {
          updateLessonCoefficient([this.formData]).then(res => {
            if (res.state === 'success') {
              window.$msg('更新成功')
              this.$refs['pover'].doClose()
              this.getListLessonCoefficient()
            }
          })
        }
      })
    },

    add() {
      this.dataList.push({
        min: null,
        max: null,
        coefficient: null,
        type: null,
        isEdit: true
      })
    },

    close(row, idx) {
      if(row.id === undefined) {
        this.dataList.splice(idx,1)
      }else {
        this.dataList[idx].isEdit = false
      }

    },

    edit(row, idx) {
      this.dataList[idx].isEdit = true
    },

    del (row, idx) {
      this.$confirm('是否删除该条数据', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          this.dataList.splice(idx, 1)
          await updateLessonCoefficient(
            this.dataList
          )
          window.$msg('删除成功')
          this.getListLessonCoefficient()
        })
        .catch(() => {
          return false
        })
    },

    async save() {
      if (this.dataList.some(item => item.min === null)) {
        window.$msg('最小出勤人数不能为空', 2)
        return false
      }
      if (this.dataList.some(item => item.max === null)) {
        window.$msg('最大出勤人数不能为空', 2)
        return false
      }
      if (this.dataList.some(item => item.coefficient === null)) {
        window.$msg('班容系数不能为空', 2)
        return false
      }
      if (this.dataList.some(item => item.type === null)) {
        window.$msg('班容类型不能为空', 2)
        return false
      }
      await updateLessonCoefficient(
        this.dataList
      )
      window.$msg('保存成功')
      this.getListLessonCoefficient()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/table.scss";

.add-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #d9d9d9;
  font-size: 14px;
  margin-top: 15px;
  padding: 10px 0;
  cursor: pointer;
  i {
    display: inline-block;
    margin-right: 8px;
  }
}
</style>
