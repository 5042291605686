var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c("el-alert", {
        attrs: {
          title: "注意问题",
          type: "warning",
          description:
            "出勤范围在0~65535，连续且不重叠，班容类型有三种（常规、竞赛、信竞），每种都需满足前面的条件",
          "show-icon": "",
        },
      }),
      _c(
        "el-table",
        {
          staticClass: "tb-list",
          staticStyle: { "margin-top": "10px" },
          attrs: {
            data: _vm.dataList,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "最小出勤人数（含）", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.isEdit
                      ? [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请填写最小出勤人数",
                              oninput: "value=value.replace(/[^0-9]/gi, '')",
                            },
                            model: {
                              value: scope.row.min,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "min", $$v)
                              },
                              expression: "scope.row.min",
                            },
                          }),
                        ]
                      : _c("span", [_vm._v(_vm._s(scope.row.min) + "（人）")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "最大出勤人数（含）", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.isEdit
                      ? [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请填写最大出勤人数",
                              oninput: "value=value.replace(/[^0-9]/gi, '')",
                            },
                            model: {
                              value: scope.row.max,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "max", $$v)
                              },
                              expression: "scope.row.max",
                            },
                          }),
                        ]
                      : _c("span", [_vm._v(_vm._s(scope.row.max) + "（人）")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "班容系数（%）", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.isEdit
                      ? [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请填写班容系数",
                              oninput: "value=value.replace(/[^0-9]/gi, '')",
                            },
                            model: {
                              value: scope.row.coefficient,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "coefficient", $$v)
                              },
                              expression: "scope.row.coefficient",
                            },
                          }),
                        ]
                      : _c("span", [_vm._v(_vm._s(scope.row.coefficient))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "班容类型", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.isEdit
                      ? [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择班容类型",
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: scope.row.type,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "type", $$v)
                                },
                                expression: "scope.row.type",
                              },
                            },
                            _vm._l(_vm.lctypeArray, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.value, value: item.value },
                              })
                            }),
                            1
                          ),
                        ]
                      : _c("span", [_vm._v(_vm._s(scope.row.type))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "250", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    !scope.row.isEdit
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.edit(scope.row, scope.$index)
                              },
                            },
                          },
                          [_vm._v("编 辑")]
                        )
                      : [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "info", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.close(scope.row, scope.$index)
                                },
                              },
                            },
                            [_vm._v("取 消")]
                          ),
                        ],
                    !scope.row.isEdit
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.del(scope.row, scope.$index)
                              },
                            },
                          },
                          [_vm._v("删 除")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticClass: "add-container",
          staticStyle: { background: "#fff", "text-align": "center" },
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "info", size: "small" },
              on: {
                click: function ($event) {
                  return _vm.add()
                },
              },
            },
            [_vm._v("新 增")]
          ),
          _vm.preserve()
            ? _c(
                "el-button",
                {
                  attrs: { type: "success", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [_vm._v("保 存")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }